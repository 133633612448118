import { tokens } from "../../theme";
import { history } from '../../utils/History';
import { 
  PeopleOutlineOutlined,
  TaskOutlined
} from "@mui/icons-material";

import { 
  Typography,
  Box, 
  useTheme} from "@mui/material";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const routeChange = (e) =>{ 
    let path = e; 
    history.navigate(path);
  }

  return (
    <Box m="20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="150px"
        gap="30px"
      >
        <Box
          gridColumn="span 3"
          backgroundColor={colors.common.white}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
            borderRadius: '6px',
            cursor: 'pointer'
          }}
          onClick={() => routeChange('/user-managment/')}
        >
          <PeopleOutlineOutlined
            sx={{ color: colors.primary[800], fontSize: "70px" }}
          />
          <Typography
            variant="h6"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "0 0 5px 0" }}
          >
            {'Gestor de usuarios'}
          </Typography>
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.common.white}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
            borderRadius: '6px',
            cursor: 'pointer'
          }}
          onClick={() => routeChange('/shipments/')}
        >
          <TaskOutlined
            sx={{ color: colors.primary[800], fontSize: "70px" }}
          />
          <Typography
            variant="h6"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "0 0 5px 0" }}
          >
            {'Cargar envíos'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
