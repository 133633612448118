import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { history } from '../../utils/History';
import { Config } from '../../Config'

const BASE_URL = Config.API_URL;

const initialState = {
    msg: null,
    user: localStorage.getItem('user') ? localStorage.getItem('user') : null,
    password: null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    error: null
}

export const createShipment = createAsyncThunk('createshipment', async(body, { rejectWithValue })=>{
    try {
        const res = await fetch(`${BASE_URL}/route/create`,{
            method: "post",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(body)
        })
        return await res.json();
    } catch (error) {
        // console.log(error);
        return rejectWithValue(error.response.data)
    }
    
})
export const editShipment = createAsyncThunk('editshipment', async(body, { rejectWithValue })=>{
    try {
        const res = await fetch(`${BASE_URL}/route/update`,{
            method: "put",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(body)
        })
        return await res.json();
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
    
})
export const getAllShipments = createAsyncThunk('getallshipments', async(body, { rejectWithValue })=>{
    try {
        const res = await fetch(`${BASE_URL}/route`,{
            method: "get",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        return await res.json();
    } catch (error) {
        // console.log(error);
        return rejectWithValue(error.response.data)
    }
    
})

const shipmentsSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        setData: (state, action) => {
            // console.log(state, action);
        },
    },  
    extraReducers: (builder) => {
        builder.addCase(createShipment.pending, (state, action) => {
        })
        builder.addCase(createShipment.fulfilled, (state, action) => {
            let {data} = action.payload;
            if (data !== undefined) {
                // history.navigate('/user-managment/');
                // console.log('success');
            }
        })
        builder.addCase(createShipment.rejected, (state, action) => {
            // console.log(action.error.message);
        })
        builder.addCase(editShipment.pending, (state, action) => {
        })
        builder.addCase(editShipment.fulfilled, (state, action) => {
            let {data} = action.payload;
            if (data !== undefined) {
                // console.log('success');
            }
        })
        builder.addCase(editShipment.rejected, (state, action) => {
            // console.log(action.error.message);
        })
        builder.addCase(getAllShipments.pending, (state, action) => {
        })
        builder.addCase(getAllShipments.fulfilled, (state, action) => {
            let {data} = action.payload;
            if (data !== undefined) {
                // console.log('success');
            }
        })
        builder.addCase(getAllShipments.rejected, (state, action) => {
            // console.log(action.error.message);
        })
    },
})

export const {logout} = shipmentsSlice.actions;
export default shipmentsSlice.reducer;