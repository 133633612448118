import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { 
  AppsOutlined,
  PeopleOutlineOutlined,
  TaskOutlined,
  LogoutOutlined,
  MenuOutlined
} from "@mui/icons-material";

import { logout } from "../../redux/Auth/authSlice";
import { history } from '../../utils/History';

const logoSidebar = require('../../assets/images/logo.png')

const Item = ({ title, to, icon, selected, setSelected, isExit, handleLogout }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (isExit) {
    return (
      <MenuItem
        style={{
          color: colors.grey[600],
        }}
        onClick={() => handleLogout()}
        icon={icon}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    );
  }else{
    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[600],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  }
};

const Sidebar = ({isOpen}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const dispatch = useDispatch();
  // const dataUser = JSON.parse(localStorage.getItem("user"));

  const handleLogout = () => {
    dispatch(logout())
    history.navigate('/login/');
  }
  const callActions = () => {
    setIsCollapsed(!isCollapsed);
    isOpen()
  }
  return (
    <Box
      sx={{
        height: "100%",
        position: "fixed",
        zIndex: "1",
        top: 0,
        left: 0,
        backgroundColor: "#111",
        overflowX: "hidden",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[800]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.yellow[600]} !important`,
        },
        "& .pro-menu-item": {
          color: `${colors.common.white} !important`,
          borderLeft: `2px solid transparent`
        },
        "& .pro-menu-item.active": {
          color: `${colors.common.white} !important`,
          borderLeft: `2px solid ${colors.yellow[600]}`
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => callActions() }
            icon={isCollapsed ? <MenuOutlined /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.yellow[600],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                ml="15px"
              >
                {/* <Typography variant="h3" color={colors.grey[100]}>
                  {dataUser.first_name}
                </Typography> */}
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlined 
                  style={{
                    color: colors.yellow[600],
                  }}/>
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={logoSidebar}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              {/* <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Ed Roh
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  VP Fancy Admin
                </Typography>
              </Box> */}
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Panel"
                to="/dashboard/"
                icon={<AppsOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Gestor de usuarios"
                to="/user-managment/"
                icon={<PeopleOutlineOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Cargar envíos"
                to="/shipments/"
                icon={<TaskOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Data
              </Typography> */}
          </Box>
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Cerrar sesión"
                icon={<LogoutOutlined />}
                isExit={true}
                handleLogout={handleLogout}
              />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
