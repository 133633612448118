import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { 
  SettingsOutlined,
} from "@mui/icons-material";

const Header = ({ title }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dataUser = JSON.parse(localStorage.getItem("user"));
  return (
    <Box 
      mb="30px"
      sx={{ 
        backgroundColor: colors.primary[800],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '20px',
        margin: '20px',
        width: '100% !important'
        
      }}
    >
      <div>
        <Typography
          variant="h6"
          color={colors.common.white}
          fontWeight="regular"
          sx={{ m: "0 0 5px 0" }}
        >
          {dataUser.user_type.toUpperCase()}
        </Typography>
        <Typography 
          variant="h2" 
          color={colors.common.white}
          fontWeight="bold"
        >
          {dataUser.first_name} {dataUser.last_name}
        </Typography>
      </div>
      <div>
        <SettingsOutlined
          sx={{ color: colors.common.white, fontSize: "44px" }}
        />
      </div>
    </Box>
  );
};

export default Header;
