import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { history } from '../../utils/History';
import { Config } from '../../Config'

const BASE_URL = Config.API_URL;

const initialState = {
    msg: null,
    user: localStorage.getItem('user') ? localStorage.getItem('user') : null,
    password: null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    error: null
}

export const createUser = createAsyncThunk('createuser', async(body, { rejectWithValue })=>{
    try {
        const res = await fetch(`${BASE_URL}/user/create`,{
            method: "post",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(body)
        })
        return await res.json();
    } catch (error) {
        // console.log(error);
        return rejectWithValue(error.response.data)
    }
    
})
export const editUser = createAsyncThunk('edituser', async(body, { rejectWithValue })=>{
    try {
        const res = await fetch(`${BASE_URL}/user/Update`,{
            method: "put",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(body)
        })
        return await res.json();
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
    
})
export const getAllUsers = createAsyncThunk('getallusers', async(body, { rejectWithValue })=>{
    try {
        const res = await fetch(`${BASE_URL}/user`,{
            method: "get",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        return await res.json();
    } catch (error) {
        // console.log(error);
        return rejectWithValue(error.response.data)
    }
    
})
export const editUserPassword = createAsyncThunk('edituserpassword', async(body, { rejectWithValue })=>{
    try {
        const res = await fetch(`${BASE_URL}/user/me/password`,{
            method: "put",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(body)
        })
        return await res.json();
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
    
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        setData: (state, action) => {
            // console.log(state, action);
        },
    },  
    extraReducers: (builder) => {
        builder.addCase(createUser.pending, (state, action) => {
        })
        builder.addCase(createUser.fulfilled, (state, action) => {
            let {data} = action.payload;
            if (data !== undefined) {
                // history.navigate('/user-managment/');
                // console.log('success');
            }
        })
        builder.addCase(createUser.rejected, (state, action) => {
            // console.log(action.error.message);
        })
        builder.addCase(getAllUsers.pending, (state, action) => {
        })
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            let {data} = action.payload;
            if (data !== undefined) {
                // console.log('success');
            }
        })
        builder.addCase(getAllUsers.rejected, (state, action) => {
            // console.log(action.error.message);
        })
        builder.addCase(editUser.pending, (state, action) => {
        })
        builder.addCase(editUser.fulfilled, (state, action) => {
            let {data} = action.payload;
            if (data !== undefined) {
                // console.log('success');
            }
        })
        builder.addCase(editUser.rejected, (state, action) => {
            // console.log(action.error.message);
        })
        builder.addCase(editUserPassword.pending, (state, action) => {
        })
        builder.addCase(editUserPassword.fulfilled, (state, action) => {
            let {data} = action.payload;
            if (data !== undefined) {
                // console.log('success');
            }
        })
        builder.addCase(editUserPassword.rejected, (state, action) => {
            // console.log(action.error.message);
        })
    },
})

export const {logout} = userSlice.actions;
export default userSlice.reducer;