import { configureStore } from '@reduxjs/toolkit'
import authSlice from "./redux/Auth/authSlice";
import userSlice from "./redux/Auth/userSlice";
import shipmentsSlice from "./redux/Auth/shipmentsSlice";

const store = configureStore({
    reducer:{
        user: authSlice,
        createUser: userSlice,
        shipments: shipmentsSlice,
    }
})
export default store;