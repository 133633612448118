import * as React from 'react'
import { Box, Button, Grid, TextField, Card, Snackbar, Alert, MenuItem} from "@mui/material";
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import { useTheme, Typography } from "@mui/material";
import { history } from '../../utils/History';
import { createUser, editUser } from "../../redux/Auth/userSlice";
import { useDispatch } from 'react-redux';
import { IMaskInput } from 'react-imask';
import { useLocation } from 'react-router-dom';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(000) 0000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function UserNew() {
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal } = state;
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('error');
  const [msg, setMsg] = React.useState(null);
  const [userTypeList, setUserTypeList] = React.useState([
    {
      value: 'user',
      label: 'Normal',
    },
    {
      value: 'admin',
      label: 'Admin',
    }
  ]);

  const translate = (e) =>{ 
    if (e!=null) {
      switch (e) {
        case "Normal":
          return "user"
          break;
        case "Admin":
          return "admin"
          break;
      
        default:
          return e;
          break;
      }
    }
  }
  
  const [email, setEmail] = React.useState(location.state != null ? location.state.email : null);
  const [firstName, setFirstName] = React.useState(location.state != null ? location.state.first_name : null);
  const [lastName, setLastName] = React.useState(location.state != null ? location.state.last_name : null);
  const [phone, setPhone] = React.useState(location.state != null ? location.state.phone : null);
  const [userType, setUserType] = React.useState(location.state != null ? translate(location.state.user_type) : "user");
  const [address, setAddress] = React.useState(location.state != null ? location.state.address : null);
  const [password, setPassword] = React.useState(null);

  const setEmailData = (e) => {
    if (e != null) {
      setEmail(e.target.value)
    }
  }
  const setFirstNameData = (e) => {
    if (e != null) {
      setFirstName(e.target.value)
    }
  }
  const setLastNameData = (e) => {
    if (e != null) {
      setLastName(e.target.value)
    }
  }
  const setPhoneData = (e) => {
    if (e != null) {
      setPhone(e.target.value)
    }
  }
  const setUserTypeData = (e) => {
    if (e != null) {
      setUserType(e.target.value)
    }
  }
  const setAddressData = (e) => {
    if (e != null) {
      setAddress(e.target.value)
    }
  }
  const setPasswordData = (e) => {
    if (e != null) {
      setPassword(e.target.value)
    }
  }
  const isEmail = (email) => {
    if (email) {
      var regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regexp.test(String(email).toLowerCase());
    }else{
      return null
    }
  }
  const handleCreateUser = async () => {
    if (email === null || email === undefined || email === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El correo no puede estar vacio')
      return;
    }else{
      if (!isEmail(email)) {
        setOpenAlert(true)
        setSeverity('error')
        setMsg('Formato de correo inválido')
        return;
      }
    }
    if (firstName === null || firstName === undefined || firstName === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El nombre no puede estar vacio')
      return;
    }
    if (lastName === null || lastName === undefined || lastName === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El apellido no puede estar vacio')
      return;
    }
    if (phone === null || phone === undefined || phone === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El teléfono no puede estar vacio')
      return;
    }
    if (userType === null || userType === undefined || userType === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El tipo de usuario no puede estar vacio')
      return;
    }
    if (location.state == null) {
      if (password === null || password === undefined || password === '') {
        setOpenAlert(true)
        setSeverity('error')
        setMsg('La contraseña no puede estar vacia')
        return;
      }
    }
    setLoading(true);
    dispatch(
      location.state != null ?
        editUser({
          email: email, 
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          address: address,
          user_type: userType
        })
      :
        createUser({
          email: email, 
          password: password,
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          address: address,
          user_type: userType
        })
    )
    .then((res) => {
        setLoading(false);
        let { Error, message, stage } = res.payload;
        if (stage === "200") {
          if (message) {
            setOpenAlert(true)
            setSeverity('success')
            setMsg(message)
            setTimeout(() => {
              history.navigate('/user-managment/');
            }, 3000);
          }
        }else{
          if (Error) {
            setOpenAlert(true)
            setSeverity('error')
            setMsg(Error)
          }
        }
       
    })
    .catch((error) => {
      setOpenAlert(true)
      setSeverity('error')
      setMsg(error)
      setLoading(false);
    })
}

  return (
    <Box m="0px 20px 20px 20px">
      <Box sx={{ width: '100% !important' }} display="flex" justifyContent="space-between" alignItems="center">
        <Typography
            variant="h3"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "5px 0 5px 0" }}
          >
            Crear usuario 
        </Typography>
      </Box>
      <Card>
        <Grid 
            container 
            height="inherit" 
            display={'flex'} 
            justifyContent={'flex-start'} 
            alignItems={'center'}
            sx={{
              padding: '20px'
            }}
        >
            <Grid 
                item
                md={4} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setEmailData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="email" 
                  id="email" 
                  label="Correo" 
                  type="email" 
                  variant="outlined" 
                  value={email}
                />
            </Grid>
            <Grid 
                item
                md={4} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setFirstNameData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="name" 
                  id="name" 
                  label="Nombre" 
                  type="text" 
                  variant="outlined" 
                  value={firstName}
                />
            </Grid>
            <Grid 
                item
                md={4} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setLastNameData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="user" 
                  id="user" 
                  label="Apellido" 
                  type="text" 
                  variant="outlined" 
                  value={lastName}     
                />
            </Grid>
            <Grid 
                item
                md={4} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  value={phone} 
                  onChange={(e) => setPhoneData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  id="phone" 
                  name=" phone"  
                  label="Teléfono" 
                  type="text" 
                  variant="outlined"  
                  InputProps={{inputComponent: TextMaskCustom}} 
                />
            </Grid>
            <Grid 
                item
                md={4} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
              <TextField
                select
                onChange={(e) => setUserTypeData(e)}
                sx={{width: '100%', padding: '5px'}}
                size="medium"
                margin='normal'
                className='input-custom' 
                name="typeUser" 
                id="typeUser" 
                label="Tipo de usuario" 
                type="text" 
                variant="outlined"
                defaultValue="user"
                value={userType}
              >
                {userTypeList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {location.state === null ?
              <Grid 
                item
                md={4} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
              >
                <TextField 
                  onChange={(e) => setPasswordData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  id="password" 
                  label="Contraseña" 
                  type="password" 
                  variant="outlined" 
                />
              </Grid>
              :
              null
            }
            <Grid 
                item
                md={12} 
                sm={12} 
               sm={12} 
                height="inherit" 
                display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setAddressData(e)} 
                  size="medium" 
                  sx={{width: '100%', padding: '5px'}}
                  margin='normal' 
                  className='input-custom' 
                  multiline
                  rows={4}
                  name="address" 
                  id="address" 
                  label="Dirección" 
                  type="text" 
                  variant="outlined"      
                  value={address}             
                />
            </Grid>
            
        </Grid>
      </Card>
      <Grid 
          container 
          height="inherit" 
          display={'flex'} 
          justifyContent={'flex-end'} 
          alignItems={'center'}
      >
          <Grid 
              item
              md={4} 
              sm={4} 
             sm={12} 
              height="inherit" 
                display={'flex'} 
              justifyContent={'flex-end'} 
              alignItems={'center'}
              flexDirection={'column'}
              marginBottom={5}
          >
          <Button 
              sx={{
                  backgroundColor: colors.yellow[600],
                  color: colors.common.black,
                  width: '15em',
                  height: '45px',
                  marginTop: '30px',
                  '&:hover': {
                  backgroundColor: colors.yellow[700],
                  },
              }}
              variant="contained"
              disabled={loading}
              onClick={()=> handleCreateUser()}
            >Guardar</Button>
          </Grid>
        </Grid>
        <Snackbar anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
            <Alert onClose={() => setOpenAlert(false)} severity={severity} sx={{ width: '100%' }}>
                {msg}
            </Alert>
        </Snackbar>
    </Box>
  )
}
