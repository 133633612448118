import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { history } from '../../utils/History';
import { Config } from '../../Config'

const BASE_URL = Config.API_URL;

const initialState = {
    msg: null,
    user: localStorage.getItem('user') ? localStorage.getItem('user') : null,
    password: null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    error: null
}

export const loginUser = createAsyncThunk('loginuser', async(body, { rejectWithValue })=>{
    try {
        const res = await fetch(`${BASE_URL}/auth/login`,{
            method: "post",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(body)
        })
        return await res.json();
    } catch (error) {
        // console.log(error);
        return rejectWithValue(error.response.data)
    }
    
})

const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        setData: (state, action) => {
            // console.log(state, action);
        },
        logout: (state) => {
            state.msg = null;
            state.user = null;
            state.password = null;
            state.token = null;
            state.error = null;
            localStorage.clear();
        },
    },  
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state, action) => {
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            let {data} = action.payload;
            if (data !== undefined) {
                state.token = data.token.token;
                state.user = JSON.stringify(data.user);
                if (data.user.user_type == 'admin') {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('token', data.token.token);
                    history.navigate('/dashboard/');
                }
            }
        })
        builder.addCase(loginUser.rejected, (state, action) => {
            // console.log(action.error.message);
        })
    },
})

export const {logout} = authSlice.actions;
export default authSlice.reducer;