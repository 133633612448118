import React from 'react'
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme, Typography, IconButton, Tooltip, Snackbar, Alert, Modal, Grid, TextField, CircularProgress } from "@mui/material";
import { useDispatch } from 'react-redux';
import { getAllUsers, editUserPassword } from "../redux/Auth/userSlice";
import { 
  VisibilityOutlined,
  CreateOutlined,
  Lock
} from "@mui/icons-material";
import { history } from '../utils/History';

export default function UserManagment() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});
  const { vertical, horizontal } = state;
  const [openAlert, setOpenAlert] = React.useState(false);
  const [allDataTable, setAllDataTable] = React.useState([]);
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [severity, setSeverity] = React.useState('error');
  const [isLoading, setIsLoading] = React.useState(false);
  const [msg, setMsg] = React.useState(null);


  const setUpdateUser = (e, path) =>{ 
    var value = e.row;
    history.navigate(path, {state: value});
  }
  const routeChange = (e) =>{ 
    let path = e; 
    history.navigate(path);
  }
  const viewUser = (e) =>{ 
    var value = e.row;
    setSelectedUser(value)
    setOpen(true);
  }
  const openModalChangePassword = (e) =>{ 
    var value = e.row;
    setSelectedUser(value)
    setOpen2(true);
  }
  const setOldPasswordData = (e) => {
    if (e != null) {
      setOldPassword(e.target.value)
    }
  }
  const setNewPasswordData = (e) => {
    if (e != null) {
      setNewPassword(e.target.value)
    }
  }
  const closeCP = () => {
    setOpen2(false)
    setOldPassword('')
    setNewPassword('')
  }
  const sendCp = () => {
    // if (oldPassword === null || oldPassword === undefined || oldPassword === '') {
    //   setOpenAlert(true)
    //   setSeverity('error')
    //   setMsg('La antigua contraseña no puede estar vacia')
    //   return;
    // }
    if (newPassword === null || newPassword === undefined || newPassword === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('La nueva contraseña no puede estar vacia')
      return;
    }
    dispatch(
      editUserPassword({
        new_password: newPassword, 
        email: selectedUser.email,
      })
    )
    .then((res) => {
        setLoading(false);
        let { Error, message, stage } = res.payload;
        if (stage === "200") {
          if (message) {
            setOpenAlert(true)
            setSeverity('success')
            setMsg(message)
            setOpen2(false)
            setOldPassword('')
            setNewPassword('')
          }
        }else{
          if (Error) {
            setOpenAlert(true)
            setSeverity('error')
            setMsg(Error)
          }
        }
       
    })
    .catch((error) => {
      setOpenAlert(true)
      setSeverity('error')
      setMsg(error)
      setLoading(false);
    })
  }


  const columns = [
    {
      field: "first_name",
      headerName: "NOMBRE",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "APELLIDO",
      flex: 1,
    },
    {
      field: "email",
      headerName: "CORREO",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "TELÉFONO",
      flex: 1,
    },
    {
      field: "user_type",
      headerName: "TIPO DE USUARIO",
      flex: 1,
    },
    {
      field: "acciones",
      headerName: "ACCIONES",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Ver">
            <IconButton style={{
                border: `1px solid` + colors.yellow[600],
                margin: 2
              }}
              onClick={() => viewUser(params)}
              >
              <VisibilityOutlined style={{
                color: colors.primary[500],
              }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton 
              style={{
                border: `1px solid` + colors.yellow[600],
                margin: 2
              }}
              onClick={() => setUpdateUser(params, 'new-user')}
              >
              <CreateOutlined style={{
                color: colors.primary[500],
              }} />
            </IconButton>
            
          </Tooltip>
          <Tooltip title="Cambiar contraseña">
            <IconButton style={{
                border: `1px solid` + colors.yellow[600],
                margin: 2
              }}
              onClick={() => openModalChangePassword(params)}
              >
              <Lock style={{
                color: colors.primary[500],
              }} />
            </IconButton>
          </Tooltip>
        </>
      )
    },
  ];

  React.useEffect(() => {
    const callApi = async () => {
      setIsLoading(true)
      dispatch(getAllUsers())
      .then((res) => {
        let { Error, message, stage } = res.payload;
        if (stage === "200") {
          setIsLoading(false)
          let newData = res.payload.data;
          newData.forEach(item => {
            item.user_type = translate(item.user_type)
          });
          setAllDataTable(newData);
        }else{
          if (Error) {
            setIsLoading(false)
            setOpenAlert(true)
            setSeverity('error')
            setMsg(Error)
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setOpenAlert(true)
        setSeverity('error')
        setMsg(error)
      })
    }
    callApi()
  }, [])
  
  const translate = (e) =>{ 
    if (e!=null) {
      switch (e) {
        case "user":
          return "Normal"
          break;
        case "admin":
          return "Admin"
          break;
      
        default:
          return e;
          break;
      }
    }
  }

  const translateEnglish = (e) =>{ 
    if (e!=null) {
      switch (e) {
        case "Normal":
          return "user"
          break;
        case "Admin":
          return "admin"
          break;
      
        default:
          return e;
          break;
      }
    }
  }

  return (
    <Box m="0px 20px 20px 20px">
      <Box sx={{ width: '100% !important' }} display="flex" justifyContent="space-between" alignItems="center">
        <Typography
            variant="h3"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "5px 0 5px 0" }}
          >
            Gestor de usuarios
        </Typography>
        <Box>
          <Button 
            sx={{
                backgroundColor: colors.yellow[600],
                color: colors.common.black,
                height: '30px',
                marginTop: '3px',
                '&:hover': {
                backgroundColor: colors.yellow[700],
                },
            }}
            variant="contained"
            onClick={() => routeChange('new-user')}
          >Nuevo Usuario</Button>
        </Box>
      </Box>
      <Box
        height="100vh"
        minHeight="50vh"
        marginBottom={5}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.common.black,
          },
          "& .actions-column--cell": {
            backgroundColor: colors.yellow[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[800],
            color: colors.common.white,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.common.white,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: colors.common.white,
            backgroundColor: colors.grey[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={allDataTable}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      <Snackbar anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
          <Alert onClose={() => setOpenAlert(false)} severity={severity} sx={{ width: '100%' }}>
              {msg}
          </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography
            variant="h3"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "5px 0 5px 20px" }}
          >
              Datos del usuario
          </Typography>
          {selectedUser != null && (
            <Grid 
              container 
              height="inherit" 
              display={'flex'} 
              justifyContent={'flex-start'} 
              alignItems={'center'}
              sx={{
                padding: '20px'
              }}
            >
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="email" 
                    id="email" 
                    label="Correo" 
                    type="email" 
                    variant="outlined" 
                    value={selectedUser.email}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="first_name" 
                    id="first_name" 
                    label="Nombre" 
                    type="text" 
                    variant="outlined" 
                    value={selectedUser.first_name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="last_name" 
                    id="last_name" 
                    label="Apellido" 
                    type="text" 
                    variant="outlined" 
                    value={selectedUser.last_name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="phone" 
                    id="phone" 
                    label="Teléfono" 
                    type="text" 
                    variant="outlined" 
                    value={selectedUser.phone}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="user_type" 
                    id="user_type" 
                    label="Tipo de usuario" 
                    type="text" 
                    variant="outlined" 
                    value={selectedUser.user_type}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={12} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="address" 
                    id="address" 
                    label="Dirección" 
                    type="text" 
                    variant="outlined" 
                    value={selectedUser.address}
                    multiline
                    rows={4}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Button 
              sx={{
                  backgroundColor: colors.yellow[600],
                  color: colors.common.black,
                  height: '30px',
                  marginTop: '3px',
                  '&:hover': {
                  backgroundColor: colors.yellow[700],
                  },
              }}
              variant="contained"
              onClick={() => setOpen(false)}
            >Cerrar</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography
            variant="h3"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "5px 0 5px 20px" }}
          >
              Cambiar contraseña
          </Typography>
          {selectedUser != null && (
            <Grid 
              container 
              height="inherit" 
              display={'flex'} 
              justifyContent={'flex-start'} 
              alignItems={'center'}
              sx={{
                padding: '20px'
              }}
            >
              <Grid 
                  item
                  md={12} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="email" 
                    id="email" 
                    label="Correo" 
                    type="email" 
                    variant="outlined" 
                    value={selectedUser.email}
                    InputProps={{
                      readOnly: true,
                      disabled: true
                    }}
                  />
              </Grid>
              {/* <Grid 
                  item
                  md={12} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="oldPassword" 
                    id="oldPassword" 
                    label="Antigua contraseña"
                    type="text" 
                    variant="outlined" 
                    value={oldPassword}
                    onChange={(e) => setOldPasswordData(e)} 
                  />
              </Grid> */}
              <Grid 
                  item
                  md={12} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="newPassword" 
                    id="newPassword" 
                    label="Nueva contraseña" 
                    type="text" 
                    variant="outlined" 
                    value={newPassword}
                    onChange={(e) => setNewPasswordData(e)} 
                  />
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Button 
              sx={{
                  backgroundColor: colors.yellow[600],
                  color: colors.common.black,
                  height: '30px',
                  marginTop: '3px',
                  margin: '10px',
                  '&:hover': {
                  backgroundColor: colors.yellow[700],
                  },
              }}
              variant="contained"
              onClick={() => closeCP()}
            >Cerrar</Button>
            <Button 
              sx={{
                  backgroundColor: colors.yellow[600],
                  color: colors.common.black,
                  height: '30px',
                  marginTop: '3px',
                  margin: '10px',
                  '&:hover': {
                  backgroundColor: colors.yellow[700],
                  },
              }}
              variant="contained"
              onClick={() => sendCp()}
            >Continuar</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={isLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: 'auto'
        }}
      >
         <Grid 
            container 
            height="inherit" 
            display={'flex'} 
            justifyContent={'center'} 
            alignItems={'center'}
            sx={{
              padding: '20px',
              minHeight: '100vh',
            }}
          >
            <div style={{padding: 30, backgroundColor: '#fff', borderRadius: 15}}>
              <CircularProgress />
            </div>
          </Grid>
      </Modal>
    </Box>
  )
}
