import React from 'react'
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme, Typography, IconButton, Tooltip, Snackbar, Alert, Modal, Grid, TextField, MenuItem, Box, Button, CircularProgress  } from "@mui/material";
import { useDispatch } from 'react-redux';
import { getAllShipments, editShipment } from "../redux/Auth/shipmentsSlice";
import { 
  VisibilityOutlined,
  CreateOutlined,
  DirectionsBoatOutlined
} from "@mui/icons-material";
import { history } from '../utils/History';

export default function Shipments() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [selectedShipment, setSelectedShipment] = React.useState({});
  const { vertical, horizontal } = state;
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [allDataTable, setAllDataTable] = React.useState([]);
  const [severity, setSeverity] = React.useState('error');
  const [msg, setMsg] = React.useState(null);
  // eslint-disable-next-line
  const [statusList, setStatusList] = React.useState([
    {
      value: 'CARRYING',
      label: 'CARGANDO',
    },
    {
      value: 'ON_THE_WAY',
      label: 'EN CAMINO',
    },
    {
      value: 'DELAYED',
      label: 'RETRASADO',
    },
    {
      value: 'ARRIVED',
      label: 'ARRIBADO',
    },
    {
      value: 'UNLOADING',
      label: 'DESEMBARQUE',
    },
    {
      value: 'STORE',
      label: 'ALMACENADO',
    },
  ]);
  const [_status, _setStatus] = React.useState("CARRYING");


  const setUpdateShipment = (e, path) =>{ 
    var value = e.row;
    history.navigate(path, {state: value});
  }
  const routeChange = (e) =>{ 
    let path = e; 
    history.navigate(path);
  }
  const viewShipment = (e) =>{ 
    var value = e.row;
    setSelectedShipment(value)
    setOpen(true);
  }
  const changeStatus = (e) =>{ 
    var value = e.row;
    setSelectedShipment(value)
    setOpen2(true);
  }
  const setStatusData = (e) => {
    if (e != null) {
      _setStatus(e.target.value)
    }
  }
  const confirmChangeStatus = () =>{ 
    if (selectedShipment) {
      dispatch(
        editShipment({
          dar: selectedShipment.dar,
          ship: selectedShipment.ship,
          description: selectedShipment.description,
          user: selectedShipment.user,
          type: selectedShipment.type,
          state: _status,
          output_route: {
              departure_country: selectedShipment.output_route.departure_country,
              departure_date: selectedShipment.output_route.departure_date,
              departure_time: selectedShipment.output_route.departure_time,
              container: selectedShipment.output_route.container,
              port_departure: selectedShipment.output_route.port_departure
          },
          arrival_route: {
              arrival_country: selectedShipment.arrival_route.arrival_country,
              arrival_date: selectedShipment.arrival_route.arrival_date,
              arrival_time: selectedShipment.arrival_route.arrival_time,
              port_arrival: selectedShipment.arrival_route.port_arrival
          }
        })
      )
      .then((res) => {
        let { Error, message, stage } = res.payload;
        if (stage === "200") {
          setOpenAlert(true)
          setSeverity('success')
          setMsg(message)
          setOpen2(false)
          dispatch(
            getAllShipments()
          )
          .then((res) => {
            let { Error, stage } = res.payload;
            if (stage === "200") {
              setAllDataTable(res.payload.data);
            }else{
              if (Error) {
                setOpenAlert(true)
                setSeverity('error')
                setMsg(Error)
              }
            }
          })
          .catch((error) => {
            setOpenAlert(true)
            setSeverity('error')
            setMsg(error)
          })
        }else{
          if (Error) {
            setOpenAlert(true)
            setSeverity('error')
            setMsg(Error)
          }
        }
      })
      .catch((error) => {
        setOpenAlert(true)
        setSeverity('error')
        setMsg(error)
      })
    }else{
      setOpenAlert(true)
      setSeverity('error')
      setMsg('Debe seleccionar un registro')
    }
  }

  const columns = [
    { 
      field: "eta",
      headerName: "ETA", 
      flex: 1 
    },
    { 
      field: "description", 
      headerName: "DESCRIPCIÓN", 
      flex: 1 
    },
    { 
      field: "dar", 
      headerName: "DAR", 
      flex: 1 
    },
    { 
      field: "business_name",
      headerName: "EMPRESA", 
      flex: 1 
    },
    {
      field: "ship",
      headerName: "BUQUE",
      flex: 1,
    },
    {
      field: "state",
      headerName: "ESTATUS",
      flex: 1,
    },
    {
      field: "type",
      headerName: "TIPO",
      flex: 1,
    },
    {
      field: "user",
      headerName: "USUARIO",
      flex: 1,
    },
    {
      field: "acciones",
      headerName: "ACCIONES",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Ver">
            <IconButton style={{
                border: `1px solid` + colors.yellow[600],
                margin: 2
              }}
              onClick={() => viewShipment(params)}
              >
              <VisibilityOutlined style={{
                color: colors.primary[500],
              }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton 
              style={{
                border: `1px solid` + colors.yellow[600],
                margin: 2
              }}
              onClick={() => setUpdateShipment(params, 'new-shipment')}
              >
              <CreateOutlined style={{
                color: colors.primary[500],
              }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar Estatus">
            <IconButton 
              style={{
                border: `1px solid` + colors.yellow[600],
                margin: 2
              }}
              onClick={() => changeStatus(params)}
              >
              <DirectionsBoatOutlined style={{
                color: colors.primary[500],
              }} />
            </IconButton>
          </Tooltip>
        </>
      )
    },
  ];
  React.useEffect(() => {
    const callApi = async () => {
      setIsLoading(true)
      dispatch(getAllShipments())
      .then((res) => {
        let { Error, stage } = res.payload;
        if (stage === "200") {
          setIsLoading(false);
          let newData = res.payload.data;
          newData.forEach(item => {
            item.state = translate(item.state)
            item.type = translate2(item.type)
            item.eta = item.arrival_route.arrival_date
            item.business_name = item.business.name
          });
          setAllDataTable(newData);
        }else{
          if (Error) {
            setIsLoading(false);
            setOpenAlert(true)
            setSeverity('error')
            setMsg(Error)
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpenAlert(true)
        setSeverity('error')
        setMsg(error)
      })
    }
    callApi();
    // return () => {
    //   setTimeout(() => {
    //     callApi()
    //   }, 1000);
    // }
  }, [])
  const translate = (e) =>{ 
    if (e!=null) {
      switch (e) {
        case "CARRYING":
          return "CARGANDO"
          break;
        case "ON_THE_WAY":
          return "EN CAMINO"
          break;
        case "DELAYED":
          return "RETRASADO"
          break;
        case "ARRIVED":
          return "ARRIBADO"
          break;
        case "UNLOADING":
          return "DESEMBARQUE"
          break;
        case "STORE":
          return "ALMACENADO"
          break;
      
        default:
          return e
          break;
      }
    }
  }
  const translateEnglish = (e) =>{ 
    if (e!=null) {
      switch (e) {
        case "CARGANDO":
          return "CARRYING"
          break;
        case "EN CAMINO":
          return "ON_THE_WAY"
          break;
        case "RETRASADO":
          return "DELAYED"
          break;
        case "ARRIBADO":
          return "ARRIVED"
          break;
        case "DESEMBARQUE":
          return "UNLOADING"
          break;
        case "ALMACENADO":
          return "STORE"
          break;
      
        default:
          return e
          break;
      }
    }
  }
  const translate2 = (e) =>{ 
    if (e!=null) {
      switch (e) {
        case "ARRIVES":
          return "ARRIBOS"
          break;
        case "TRANSIT":
          return "EN TRANSITO"
          break;
      
        default:
          return e
          break;
      }
    }
  }
  return (
    <Box m="0px 20px 20px 20px">
      <Box sx={{ width: '100% !important' }} display="flex" justifyContent="space-between" alignItems="center">
        <Typography
            variant="h3"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "5px 0 5px 0" }}
          >
            Cargar envíos
        </Typography>
        <Box>
          <Button 
            sx={{
                backgroundColor: colors.yellow[600],
                color: colors.common.black,
                height: '30px',
                marginTop: '3px',
                '&:hover': {
                backgroundColor: colors.yellow[700],
                },
            }}
            variant="contained"
            onClick={() => routeChange('new-shipment')}
          >Nuevo Envio</Button>
        </Box>
      </Box>
      <Box
        height="100vh"
        minHeight="50vh"
        marginBottom={5}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.common.black,
          },
          "& .actions-column--cell": {
            backgroundColor: colors.yellow[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[800],
            color: colors.common.white,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.common.white,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: colors.common.white,
            backgroundColor: colors.grey[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={allDataTable}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      <Snackbar anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
          <Alert onClose={() => setOpenAlert(false)} severity={severity} sx={{ width: '100%' }}>
              {msg}
          </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: 'auto'
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          maxHeight: '75vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          <Typography
            variant="h3"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "5px 0 5px 20px" }}
          >
              Datos del usuario
          </Typography>
          {selectedShipment != null && (
            <Grid 
              container 
              height="inherit" 
              display={'flex'} 
              justifyContent={'flex-start'} 
              alignItems={'center'}
              sx={{
                padding: '20px'
              }}
            >
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="dar" 
                    id="dar" 
                    label="Dar" 
                    type="dar" 
                    variant="outlined" 
                    value={selectedShipment.dar}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="ship" 
                    id="ship" 
                    label="Buque" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment.ship}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="type" 
                    id="type" 
                    label="Tipo" 
                    type="text" 
                    variant="outlined" 
                    value={translate2(selectedShipment.type)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="type" 
                    id="type" 
                    label="Estatus" 
                    type="text" 
                    variant="outlined" 
                    value={translate(selectedShipment.state)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="ship" 
                    id="ship" 
                    label="Maritima" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment.maritime}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={12} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="description" 
                    id="description" 
                    label="Descripción" 
                    type="text" 
                    multiline
                    rows={2}
                    variant="outlined" 
                    value={selectedShipment.description}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="ship" 
                    id="ship" 
                    label="Empresa" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment.business?.name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="ship" 
                    id="ship" 
                    label="Código de empresa" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment.business?.cod}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item 
                  sm={12}
                  height="inherit" 
                  display={'flex'} 
                  justifyContent={'flex-start'} 
                  alignItems={'flex-start'}
                  flexDirection={'column'}
                  
              >
                <Typography
                    variant="h5"
                    color={colors.primary[800]}
                    fontWeight="regular"
                    sx={{ m: "5px 0 5px 10px" }}
                  >
                    Embarque
                </Typography>
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="departure_country" 
                    id="departure_country" 
                    label="País de salida" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.output_route?.departure_country}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="departure_date" 
                    id="departure_date" 
                    label="Fecha de salida" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.output_route?.departure_date}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="departure_time" 
                    id="departure_time" 
                    label="Hora de salida" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.output_route?.departure_time}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="port_departure" 
                    id="port_departure" 
                    label="Puerto de salida" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.output_route?.port_departure}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="container" 
                    id="container" 
                    label="Contenedor" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.output_route?.container}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item 
                  sm={12}
                  height="inherit" 
                  display={'flex'} 
                  justifyContent={'flex-start'} 
                  alignItems={'flex-start'}
                  flexDirection={'column'}
                  
              >
                <Typography
                    variant="h5"
                    color={colors.primary[800]}
                    fontWeight="regular"
                    sx={{ m: "5px 0 5px 10px" }}
                  >
                    Arribo
                </Typography>
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="arrival_country" 
                    id="arrival_country" 
                    label="País de arribo" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.arrival_route?.arrival_country}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="arrival_date" 
                    id="arrival_date" 
                    label="Fecha de arribo" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.arrival_route?.arrival_date}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="arrival_time" 
                    id="arrival_time" 
                    label="Hora de arribo" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.arrival_route?.arrival_time}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                    display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'column'}
              >
                  <TextField 
                    size="medium" sx={{width: '100%', padding: '5px'}} 
                    margin='normal' 
                    className='input-custom' 
                    name="port_arrival" 
                    id="port_arrival" 
                    label="Puerto de arribo" 
                    type="text" 
                    variant="outlined" 
                    value={selectedShipment?.arrival_route?.port_arrival}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Button 
              sx={{
                  backgroundColor: colors.yellow[600],
                  color: colors.common.black,
                  height: '30px',
                  marginTop: '3px',
                  '&:hover': {
                  backgroundColor: colors.yellow[700],
                  },
              }}
              variant="contained"
              onClick={() => setOpen(false)}
            >Cerrar</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: 'auto'
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          maxHeight: '75vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          <Typography
            variant="h3"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "5px 0 5px 20px" }}
          >
              Cambiar estatus del envió
          </Typography>
          <Grid 
              container 
              height="inherit" 
              display={'flex'} 
              justifyContent={'flex-start'} 
              alignItems={'center'}
              sx={{
                padding: '20px'
              }}
            >
              <Grid 
                  item
                  md={6} 
                  sm={12} 
                  height="inherit" 
                  display={'flex'} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                  flexDirection={'row'}
                  
              >
                  <TextField
                    select
                    onChange={(e) => setStatusData(e)}
                    sx={{width: '100%', padding: '5px'}}
                    size="medium"
                    margin='normal'
                    className='input-custom' 
                    name="status" 
                    id="status" 
                    label="Estatus" 
                    type="text" 
                    variant="outlined"
                    defaultValue="DELAYED"
                    value={translateEnglish(selectedShipment.state)}
                  >
                    {statusList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
              </Grid>
              
            </Grid>
          <Box
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
             <Button 
                sx={{
                    backgroundColor: colors.yellow[600],
                    color: colors.common.black,
                    height: '30px',
                    marginTop: '3px',
                    margin: '3px',
                    '&:hover': {
                    backgroundColor: colors.yellow[700],
                    },
                }}
                variant="contained"
                onClick={() => confirmChangeStatus()}
              >Confirmar</Button>
            <Button 
              sx={{
                  backgroundColor: colors.yellow[600],
                  color: colors.common.black,
                  height: '30px',
                  marginTop: '3px',
                  margin: '3px',
                  '&:hover': {
                  backgroundColor: colors.yellow[700],
                  },
              }}
              variant="contained"
              onClick={() => setOpen2(false)}
            >Cerrar</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={isLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: 'auto'
        }}
      >
         <Grid 
            container 
            height="inherit" 
            display={'flex'} 
            justifyContent={'center'} 
            alignItems={'center'}
            sx={{
              padding: '20px',
              minHeight: '100vh',
            }}
          >
            <div style={{padding: 30, backgroundColor: '#fff', borderRadius: 15}}>
              <CircularProgress />
            </div>
          </Grid>
      </Modal>
    </Box>
  )
}
