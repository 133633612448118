import React from 'react'
import { 
    Alert, 
    Snackbar, 
    TextField, 
    Grid, 
    useTheme,
    Button} from "@mui/material";
import { tokens } from "../theme";
import { useDispatch } from 'react-redux';
import { loginUser } from "../redux/Auth/authSlice";
import { history } from '../utils/History';

const logo = require('../assets/images/logo.png')

export default function Login() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [severity, setSeverity] = React.useState('error');
    const [msg, setMsg] = React.useState(null);

    

    React.useEffect(() => {
        const auth = localStorage.getItem('token');
        if (auth) {
            history.navigate('/dashboard/');
        }
    }, [])
    

    const setUserData = (e) => {
        var value = e.target.value
        if (value != null) {
            setUser(value)
        }
    }
    const setPasswordData = (e) => {
        var value = e.target.value
        if (value != null) {
            setPassword(value)
        }
    }
    const handleLogin = async () => {
        setLoading(true);
        dispatch(
            loginUser({
                User: user,
                Password: password
            }),
        )
        .then((res) => {
            setLoading(false);
            let { Error, stage, data } = res.payload;
            if (stage === "200") {
                if (data.user.user_type != 'admin') {
                    setOpenAlert(true)
                    setSeverity('error')
                    setMsg("El usuario no posee los permisos necesarios para ingresar al portal web")
                }
            }else{
              if (Error) {
                setOpenAlert(true)
                setSeverity('error')
                setMsg(Error)
              }
            }
        })
        .catch((error) => {
            setLoading(false);
        })
    }
    return (
        <Grid 
            container
            minHeight="100vh" 
            sx={{
                backgroundColor: colors.primary[800],
            }}
        >
            <Grid 
                item 
                md={5} 
                sm={5}
                height="inherit" 
                display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                className="hidden"
            >
                <img
                    src={logo}
                    alt='...'
                    loading="lazy"
                    className='image-login'
                />
            </Grid>
            <Grid 
                item 
                md={7} 
                sm={12}
                height="inherit" 
                display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
                sx={{
                    backgroundColor: colors.common.white,
                }}
            >
                 <h1 
                    className='title-login'
                    >  Bienvenido a Darwin Logistics</h1>
                <Grid 
                    container 
                    height="inherit" 
                    display={'flex'} 
                    justifyContent={'center'} 
                    alignItems={'center'}
                >
                    <Grid 
                        item
                       sm={12} 
                        height="inherit" 
                        display={'flex'} 
                        justifyContent={'center'} 
                        alignItems={'center'}
                    >
                        <TextField onChange={(e) => setUserData(e)} margin='normal' className='input-custom' id="user" label="Usuario" type="text" variant="outlined" />
                    </Grid>
                    <Grid 
                        item
                       sm={12} 
                        height="inherit" 
                        display={'flex'} 
                        justifyContent={'center'} 
                        alignItems={'center'}
                    >
                         <TextField onChange={(e) => setPasswordData(e)} margin='normal' className='input-custom' id="password" label="Contraseña" type="password" variant="outlined" />
                    </Grid>
                    <Button 
                        sx={{
                            backgroundColor: colors.yellow[600],
                            color: colors.common.black,
                            width: '15em',
                            height: '45px',
                            marginTop: '30px',
                            '&:hover': {
                            backgroundColor: colors.yellow[700],
                            },
                        }}
                        variant="contained"
                        onClick={()=> handleLogin()}
                        disabled={loading}
                      >Iniciar sesión</Button>
                </Grid>
            </Grid>
            <Snackbar open={openAlert} autoHideDuration={4000} onClose={() => setOpenAlert(false)}>
                <Alert onClose={() => setOpenAlert(false)} severity={severity} sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>
        </Grid>
    )
}
