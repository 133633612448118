import * as React from 'react'
import { Box, Button, Grid, TextField, Card, Snackbar, Alert, MenuItem} from "@mui/material";
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import { useTheme, Typography } from "@mui/material";
import { history } from '../../utils/History';
import { createShipment, editShipment } from "../../redux/Auth/shipmentsSlice";
import { getAllUsers } from "../../redux/Auth/userSlice";
import { useDispatch } from 'react-redux';
import { IMaskInput } from 'react-imask';
import { useLocation } from 'react-router-dom';
import { DateTimePicker, esES } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(000) 0000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function ShipmentsNew() {
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal } = state;
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('error');
  const [msg, setMsg] = React.useState(null);
  
  const [statusList1, setStatusList1] = React.useState([
    {
      value: 'CARRYING',
      label: 'CARGANDO',
    },
    {
      value: 'ON_THE_WAY',
      label: 'EN CAMINO',
    },
    {
      value: 'DELAYED',
      label: 'RETRASADO',
    }
  ]);
  const [statusList2, setStatusList2] = React.useState([
    {
      value: 'ARRIVED',
      label: 'ARRIBADO',
    },
    {
      value: 'UNLOADING',
      label: 'DESEMBARQUE',
    },
    {
      value: 'STORE',
      label: 'ALMACENADO',
    },
  ]);
  const [allDataUsers, setAllDataUsers] = React.useState([]);
  const [typeList, setTypeList] = React.useState([
    {
      value: 'ARRIVES',
      label: 'ARRIBOS',
    },
    {
      value: 'TRANSIT',
      label: 'TRANSITO',
    }
  ]);

  const translateEnglish = (e) =>{ 
    if (e!=null) {
      switch (e) {
        case "CARGANDO":
          return "CARRYING"
          break;
        case "EN CAMINO":
          return "ON_THE_WAY"
          break;
        case "RETRASADO":
          return "DELAYED"
          break;
        case "ARRIBADO":
          return "ARRIVED"
          break;
        case "DESEMBARQUE":
          return "UNLOADING"
          break;
        case "ALMACENADO":
          return "STORE"
          break;
      
        default:
          return e
          break;
      }
    }
  }
  const translateEnglish2 = (e) =>{ 
    if (e!=null) {
      switch (e) {
        case "ARRIBOS":
          return "ARRIVES"
          break;
        case "EN TRANSITO":
          return "TRANSIT"
          break;
      
        default:
          return e
          break;
      }
    }
  }

  const [dar, setDar] = React.useState(location.state != null ? location.state.dar : '');
  const [ship, setShip] = React.useState(location.state != null ? location.state.ship : '');
  const [maritime, setMaritime] = React.useState(location.state != null ? location.state.maritime : '');
  const [description, setDescription] = React.useState(location.state != null ? location.state.description : '');
  const [user, setUser] = React.useState(location.state != null ? location.state.user : '');
  const [status, setStatus] = React.useState(location.state != null ? translateEnglish(location.state.state) : "");
  const [type, setType] = React.useState(location.state != null ? translateEnglish2(location.state.type) : "ARRIVES");

  const [departureCountry, setDepartureCountry] = React.useState(location.state != null ? location.state.output_route.departure_country : '');
  const [departureDate, setDepartureDate] = React.useState(location.state != null ? dayjs(location.state.output_route.departure_date) : dayjs(new Date()));
  const [departureDateSend, setDepartureDateSend] = React.useState(location.state != null ? location.state.output_route.departure_date : null);
  const [departureTimeSend, setDepartureTimeSend] = React.useState(location.state != null ? location.state.output_route.departure_time : '');
  const [container, setContainer] = React.useState(location.state != null ? location.state.output_route.container : '');
  const [departurePort, setDeparturePort] = React.useState(location.state != null ? location.state.output_route.port_departure : '');

  const [arrivalCountry, setArrivalCountry] = React.useState(location.state != null ? location?.state?.arrival_route?.arrival_country : '');
  const [arrivalDate, setArrivalDate] = React.useState(location.state != null ? dayjs(location.state.arrival_route.arrival_date) : dayjs(new Date()));
  const [arrivalDateSend, setArrivalDateSend] = React.useState(location.state != null ? location?.state?.arrival_route?.arrival_date : '');
  const [arrivalTimeSend, setArrivalTimeSend] = React.useState(location.state != null ? location?.state?.arrival_route?.arrival_time : '');
  const [arrivalPort, setArrivalPort] = React.useState(location.state != null ? location?.state?.arrival_route?.port_arrival : '');

  //patch 13/02/2024
  const [corporation, setCorporation] = React.useState(location.state != null ? location?.state?.business?.name : '');
  const [corporationCod, setCorporationCod] = React.useState(location.state != null ? location?.state?.business?.cod : '');

  const setShipData = (e) => {
    if (e != null) {
      setShip(e.target.value)
    }
  }
  const setMaritimeData = (e) => {
    if (e != null) {
      setMaritime(e.target.value)
    }
  }
  const setDescriptionData = (e) => {
    if (e != null) {
      setDescription(e.target.value)
    }
  }
  const setCorporationData = (e) => {
    if (e != null) {
      setCorporation(e.target.value)
    }
  }
  const setCorporationCodData = (e) => {
    if (e != null) {
      setCorporationCod(e.target.value)
    }
  }
  const setUserData = (e) => {
    if (e != null) {
      setUser(e.target.value)
    }
  }
  const setStatusData = (e) => {
    if (e != null) {
      setStatus(e.target.value)
    }
  }
  const setTypeData = (e) => {
    if (e != null) {
      setType(e.target.value)
    }
  }

  const setDepartureCountryData = (e) => {
    if (e != null) {
      setDepartureCountry(e.target.value)
    }
  }
  const setDepartureDateData = (e) => {
    var date = dayjs(e).format('YYYY-MM-DDThh:mm:ss A'); 
    if (e != null) {
      setDepartureDate(e)
      setDepartureDateSend(date.split('T')[0])
      setDepartureTimeSend(date.split('T')[1])
    }
  }
  const setContainerData = (e) => {
     if (e != null) {
      setContainer(e.target.value)
    }
  }
  const setDeparturePortData = (e) => {
    if (e != null) {
      setDeparturePort(e.target.value)
    }
  }

  const setArrivalCountryData = (e) => {
    if (e != null) {
      setArrivalCountry(e.target.value)
    }
  }
  const setArrivalDateData = (e) => {
    var date = dayjs(e).format('YYYY-MM-DDThh:mm:ss A'); 
    if (e != null) {
      setArrivalDate(e)
      setArrivalDateSend(date.split('T')[0])
      setArrivalTimeSend(date.split('T')[1])
    }
  }
  const setArrivalPortData = (e) => {
    if (e != null) {
      setArrivalPort(e.target.value)
    }
  }


  const handleCreateShipment = async () => {
    // if (dar === null || dar === undefined || dar === '') {
    //   setOpenAlert(true)
    //   setSeverity('error')
    //   setMsg('El DAR no puede estar vacio')
    //   return;
    // }
    if (ship === null || ship === undefined || ship === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El buque no puede estar vacio')
      return;
    }
    if (maritime === null || maritime === undefined || maritime === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El campo Maritima no puede estar vacio')
      return;
    }
    if (description === null || description === undefined || description === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('La descripcion no puede estar vacia')
      return;
    }
    if (user === null || user === undefined || user === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El usuario no puede estar vacio')
      return;
    }
    if (status === null || status === undefined || status === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El estatus no puede estar vacio')
      return;
    }
    if (corporation === null || corporation === undefined || corporation === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('La empresa no puede estar vacia')
      return;
    }
    if (corporationCod === null || corporationCod === undefined || corporationCod === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El código de empresa no puede estar vacio')
      return;
    }
    if (type === null || type === undefined || type === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El tipo no puede estar vacio')
      return;
    }

    if (departureCountry === null || departureCountry === undefined || departureCountry === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El País de salida no puede estar vacio')
      return;
    }
    if (departureDateSend === null || departureDateSend === undefined || departureDateSend === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('La Fecha de salida no puede estar vacia')
      return;
    }
    if (departureTimeSend === null || departureTimeSend === undefined || departureTimeSend === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('La Hora de salida no puede estar vacia')
      return;
    }
    if (container === null || container === undefined || container === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El Contenedor no puede estar vacio')
      return;
    }
    if (departurePort === null || departurePort === undefined || departurePort === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El Puerto de salida no puede estar vacio')
      return;
    }
    if (arrivalCountry === null || arrivalCountry === undefined || arrivalCountry === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El País de arribo no puede estar vacio')
      return;
    }
    if (arrivalDateSend === null || arrivalDateSend === undefined || arrivalDateSend === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('La Fecha de arribo no puede estar vacia')
      return;
    }
    if (arrivalTimeSend === null || arrivalTimeSend === undefined || arrivalTimeSend === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('La Hora de arribo no puede estar vacia')
      return;
    }
    if (arrivalPort === null || arrivalPort === undefined || arrivalPort === '') {
      setOpenAlert(true)
      setSeverity('error')
      setMsg('El Puerto de arribo no puede estar vacio')
      return;
    }


    setLoading(true);
    dispatch(
      location.state != null ?
        editShipment({
          dar: location.state.dar ? location.state.dar : '',
          maritime: maritime,
          ship: ship,
          description: description,
          user: user,
          state: status,
          type: type,
          output_route: {
              departure_country: departureCountry,
              departure_date: departureDateSend,
              departure_time: departureTimeSend,
              container: container,
              port_departure: departurePort
          },
          arrival_route: {
              arrival_country: arrivalCountry,
              arrival_date: arrivalDateSend,
              arrival_time: arrivalTimeSend,
              port_arrival: arrivalPort
          },
          business: {
            name: corporation,
            cod: corporationCod
          }
        })
      :
        createShipment({
          dar: "d",  
          ship: ship,
          maritime: maritime,
          description: description,
          user: user,
          state: status,
          type: type,
          output_route: {
              departure_country: departureCountry,
              departure_date: departureDateSend,
              departure_time: departureTimeSend,
              container: container,
              port_departure: departurePort
          },
          arrival_route: {
              arrival_country: arrivalCountry,
              arrival_date: arrivalDateSend,
              arrival_time: arrivalTimeSend,
              port_arrival: arrivalPort
          },
          business: {
            name: corporation,
            cod: corporationCod
          }
        })
    )
    .then((res) => {
        setLoading(false);
        let { Error, message, stage } = res.payload;
        if (stage === "200") {
          if (message) {
            setOpenAlert(true)
            setSeverity('success')
            setMsg(message)
            setTimeout(() => {
              history.navigate('/shipments/');
            }, 3000);
          }
        }else{
          if (Error) {
            setOpenAlert(true)
            setSeverity('error')
            setMsg(Error)
          }
        }
       
    })
    .catch((error) => {
      setOpenAlert(true)
      setSeverity('error')
      setMsg(error)
      setLoading(false);
    })
  }

  React.useEffect(() => {
    const callApi = async () => {
      dispatch(getAllUsers())
      .then((res) => {
        let { Error, message, stage } = res.payload;
        if (stage === "200") {
          setAllDataUsers(res.payload.data);
        }else{
          if (Error) {
            setOpenAlert(true)
            setSeverity('error')
            setMsg(Error)
          }
        }
      })
      .catch((error) => {
        setOpenAlert(true)
        setSeverity('error')
        setMsg(error)
      })
    }
    callApi()
  }, [])

  return (
    <Box m="0px 20px 20px 20px">
      <Box sx={{ width: '100% !important' }} display="flex" justifyContent="space-between" alignItems="center">
        <Typography
            variant="h3"
            color={colors.primary[800]}
            fontWeight="regular"
            sx={{ m: "5px 0 5px 0" }}
          >
            Crear envió
        </Typography>
      </Box>
      <Card>
        <Grid 
            container 
            height="inherit" 
            display={'flex'} 
            justifyContent={'flex-start'} 
            alignItems={'center'}
            sx={{
              padding: '20px'
            }}
        >
            {/* <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setDarData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="dar" 
                  id="dar" 
                  label="DAR" 
                  type="text" 
                  variant="outlined" 
                  value={dar}
                />
            </Grid> */}
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setShipData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="ship" 
                  id="ship" 
                  label="Buque" 
                  type="text" 
                  variant="outlined" 
                  value={ship}
                />
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
              <TextField
                select
                onChange={(e) => setTypeData(e)}
                sx={{width: '100%', padding: '5px'}}
                size="medium"
                margin='normal'
                className='input-custom' 
                name="type" 
                id="type" 
                label="Tipo" 
                type="text" 
                variant="outlined"
                defaultValue="ARRIVES"
                value={type}
              >
                {
                typeList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
                }
              </TextField>
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setMaritimeData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="ship" 
                  id="ship" 
                  label="Maritima" 
                  type="text" 
                  variant="outlined" 
                  value={maritime}
                />
            </Grid>
            <Grid 
                item
                md={12} 
                sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setDescriptionData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="description" 
                  id="description" 
                  label="Descripción" 
                  type="text" 
                  variant="outlined" 
                  multiline
                  inputProps={{
                    maxLength: 80
                  }}
                  rows={2}
                  value={description}     
                />
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField
                  select
                  onChange={(e) => setUserData(e)}
                  sx={{width: '100%', padding: '5px'}}
                  size="medium"
                  margin='normal'
                  className='input-custom' 
                  name="user" 
                  id="user" 
                  label="Usuario" 
                  type="text" 
                  variant="outlined"
                  value={user}
                >
                  {
                    allDataUsers.map((option) => (
                      <MenuItem key={option.id} value={option.email}>
                        {option.first_name} - {option.email}
                      </MenuItem>
                    ))
                  }
                </TextField>
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
              <TextField
                select
                onChange={(e) => setStatusData(e)}
                sx={{width: '100%', padding: '5px'}}
                size="medium"
                margin='normal'
                className='input-custom' 
                name="status" 
                id="status" 
                label="Estatus" 
                type="text" 
                variant="outlined"
                defaultValue={type === "ARRIVES" ? "ARRIVED" : "CARRYING"}
                value={status}
              >
                {type === "ARRIVES" ? (
                  statusList2.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )))
                  :
                  (
                  statusList1.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )))
                }
              </TextField>
            </Grid>
            <Grid 
              item
              md={6} 
              sm={12} 
              height="inherit" 
              display={'flex'} 
              justifyContent={'center'} 
              alignItems={'center'}
              flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setCorporationData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="ship" 
                  id="ship" 
                  label="Empresa" 
                  type="text" 
                  variant="outlined" 
                  value={corporation}
                />
            </Grid>
            <Grid 
              item
              md={6} 
              sm={12} 
              height="inherit" 
              display={'flex'} 
              justifyContent={'center'} 
              alignItems={'center'}
              flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setCorporationCodData(e)} 
                  size="medium" sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="ship" 
                  id="ship" 
                  label="Código de empresa" 
                  type="text" 
                  variant="outlined" 
                  value={corporationCod}
                />
            </Grid>
            <Grid 
                item 
                sm={12}
                height="inherit" 
                display={'flex'} 
                justifyContent={'flex-start'} 
                alignItems={'flex-start'}
                flexDirection={'column'}
                
            >
              <Typography
                  variant="h5"
                  color={colors.primary[800]}
                  fontWeight="regular"
                  sx={{ m: "5px 0 5px 10px" }}
                >
                  Embarque
              </Typography>
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setDepartureCountryData(e)} 
                  size="medium" 
                  sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="departure_country" 
                  id="departure_country" 
                  label="País de salida" 
                  type="text" 
                  variant="outlined" 
                  value={departureCountry}
                />
            </Grid>          
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <DateTimePicker 
                  onChange={(e) => setDepartureDateData(e)} 
                  size="medium" 
                  sx={{width: '100%', padding: '5px', marginTop: "6px"}} 
                  margin='normal' 
                  className='input-custom' 
                  name="departure_date" 
                  id="departure_date" 
                  label="Fecha y hora de salida" 
                  type="text" 
                  variant="outlined" 
                  value={departureDate}
                  // minDate={dayjs()}
                  localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                />
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setContainerData(e)} 
                  size="medium" 
                  sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="container" 
                  id="container" 
                  label="Contenedor" 
                  type="text" 
                  variant="outlined" 
                  value={container}
                />
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setDeparturePortData(e)} 
                  size="medium" 
                  sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="port_departure" 
                  id="port_departure" 
                  label="Puerto de salida" 
                  type="text" 
                  variant="outlined" 
                  value={departurePort}
                />
            </Grid>
            
            
            <Grid 
                item 
                sm={12}
                height="inherit" 
                display={'flex'} 
                justifyContent={'flex-start'} 
                alignItems={'flex-start'}
                flexDirection={'column'}
                
            >
              <Typography
                  variant="h5"
                  color={colors.primary[800]}
                  fontWeight="regular"
                  sx={{ m: "5px 0 5px 10px" }}
                >
                  Arribo
              </Typography>
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setArrivalCountryData(e)} 
                  size="medium" 
                  sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="arrival_country" 
                  id="arrival_country" 
                  label="País de arribo" 
                  type="text" 
                  variant="outlined" 
                  value={arrivalCountry}
                />
            </Grid>          
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <DateTimePicker 
                  onChange={(e) => setArrivalDateData(e)} 
                  size="medium" 
                  sx={{width: '100%', padding: '5px', marginTop: "6px"}} 
                  margin='normal' 
                  className='input-custom' 
                  name="arrival_date" 
                  id="arrival_date" 
                  label="Fecha de llegada" 
                  type="text" 
                  variant="outlined" 
                  value={arrivalDate}
                  localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                  minDate={dayjs()}
                />
            </Grid>
            <Grid 
                item
                md={6} 
                sm={12} 
               sm={12} 
                height="inherit" 
                  display={'flex'} 
                justifyContent={'center'} 
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TextField 
                  onChange={(e) => setArrivalPortData(e)} 
                  size="medium" 
                  sx={{width: '100%', padding: '5px'}} 
                  margin='normal' 
                  className='input-custom' 
                  name="port_arrival" 
                  id="port_arrival" 
                  label="Puerto de arribo" 
                  type="text" 
                  variant="outlined" 
                  value={arrivalPort}
                />
            </Grid>

        </Grid>
      </Card>
      <Grid 
          container 
          height="inherit" 
          display={'flex'} 
          justifyContent={'flex-end'} 
          alignItems={'center'}
      >
          <Grid 
              item
              md={4} 
              sm={4} 
             sm={12} 
              height="inherit" 
              display={'flex'} 
              justifyContent={'flex-end'} 
              alignItems={'center'}
              flexDirection={'column'}
              marginBottom={5}
          >
          <Button 
              sx={{
                  backgroundColor: colors.yellow[600],
                  color: colors.common.black,
                  width: '15em',
                  height: '45px',
                  marginTop: '30px',
                  '&:hover': {
                  backgroundColor: colors.yellow[700],
                  },
              }}
              variant="contained"
              disabled={loading}
              onClick={()=> handleCreateShipment()}
            >Guardar</Button>
          </Grid>
      </Grid>
      <Snackbar anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
            <Alert onClose={() => setOpenAlert(false)} severity={severity} sx={{ width: '100%' }}>
                {msg}
            </Alert>
      </Snackbar>
    </Box>
  )
}
