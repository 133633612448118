import React from 'react';
import {Outlet, Navigate} from 'react-router-dom';
import Sidebar from "../scenes/global/Sidebar";
import Header from "../components/Header";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";


const PrivateRoutes = () => {
    const auth = useSelector(state => state.user.token)
    const [isSidebar, setIsSidebar] = React.useState(true);
    const [actualClass, setActualClass] = React.useState(false);
    const isOpen = () => {
        setActualClass(!actualClass)
    }
    return (
        auth != null ? (
            <div className="app">
                <Sidebar isSidebar={isSidebar} isOpen={isOpen} />
                <main className={actualClass ? "content" : "content-open"}>
                    <Box sx={{ width: '100% !important' }} display="flex" justifyContent="space-between" alignItems="center">
                        <Header />
                    </Box>
                    <Outlet/>
                </main>
            </div>
        ) : <Navigate to="/login/" />
    )
}

export default PrivateRoutes;