import { Routes, Route, Navigate, useNavigate, useLocation} from "react-router-dom";
import { history } from './utils/History';
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Login from "./screens/Login";
import Shipments from "./screens/Shipments";
import UserManagmentNew from "./screens/UsersAdd/UserNew";
import ShipmentsNew from "./screens/ShipmentsAdd/ShipmentsNew";
import UserManagment from "./screens/UserManagment";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import PrivateRoutes from "./utils/PrivateRoute";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


function App() {
  const [theme, colorMode] = useMode();
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <Routes>
              <Route path="/" element={<PrivateRoutes />}>
                <Route path="/dashboard/" element={<Dashboard />} />
                <Route path="/shipments/" element={<Shipments />} />
                <Route path="/new-user/" element={<UserManagmentNew />} />
                <Route path="/new-shipment/" element={<ShipmentsNew />} />
                <Route path="/user-managment/" element={<UserManagment />} />
                <Route path="/team/" element={<Team />} />
                <Route path="/contacts/" element={<Contacts />} />
                <Route path="/invoices/" element={<Invoices />} />
                <Route path="/form/" element={<Form />} />
                <Route path="/bar/" element={<Bar />} />
                <Route path="/pie/" element={<Pie />} />
                <Route path="/line/" element={<Line />} />
                <Route path="/faq/" element={<FAQ />} />
                <Route path="/calendar/" element={<Calendar />} />
                <Route path="/geography/" element={<Geography />} />
              </Route>
              <Route path="/login/" element={<Login />} />
              <Route path="*" element={<Navigate to="/dashboard/" />} />
            </Routes>
        
        </ThemeProvider>
      </LocalizationProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
